import { Box, Center, Flex, Grid, GridItem, HStack, Image, Text, VStack } from "@chakra-ui/react";
import BoxArticle from "../components/BoxArticle";
import BoxText from "../components/BoxText";
import Photos from "../components/Photos";
import SectionTitle from "../components/SectionTitle";
import ShopCard from "../components/ShopCard";
import MetaTags from 'react-meta-tags';
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
import { useState } from "react";


const Mylink = ({color}) => {

    const [open, setOpen] = useState(false);
    const images=[
        { src: "/assets/img/imageone.jpg" },
        { src: "/assets/img/imagetwo.jpg" },
        { src: "/assets/img/imagethree.jpg" },
        ];

    return (
    
    <Center 
        // bgImage={"url(https://wp-mktg.prod.getty1.net/istockcontentredesign/wp-content/uploads/sites/5/bfi_thumb/2021_iStock_LatestBGTrends_Hero.jpg-37hacs394rfnlye6q17b1otbsizghpa9fhni65z6lb6gploak.jpeg)"}
        bgSize="cover"
        bgRepeat={"no-repeat"}
        >
        <MetaTags>
            <title>Omnika Dangol - Aalaya</title>
            <meta name="description" content="Omnika Dangol - Aalaya" />
            <link rel="icon" href="/assets/img/aalaya.png" />
            <meta property="og:title" content="Omnika Dangol - Aalaya" />
            <meta property="og:image" content="/assets/img/aalaya.png" />
        </MetaTags>
        <Box
            color={color} 
            padding={'1rem'} 
            maxW="100%">
            <HStack gap={'0.5rem'}>
                <Box>
                    <Image  
                        height={'9rem'}
                        width={'9rem'}
                        objectFit={'cover'}
                        borderRadius={'1rem'}
                        src="/assets/img/imageone.jpg" />
                </Box>
                <Box>
                    <Text fontSize={'1.3rem'} fontWeight="bold" >Omnika</Text>
                    <Text fontSize={'1.3rem'} fontWeight="bold" >Dangol</Text>
                    <Text fontSize={'1rem'} >@omnikadangol</Text>
                    {/* <HStack mt={'0.5rem'}> */}
                        {/* <a href="https://www.facebook.com/saiomnika"><Image height={'22px'} src="/assets/img/facebook.png"/></a> */}
                        {/* <a href="https://www.instagram.com/omnikadangol/"><Image height={'22px'} src="/assets/img/instagram.png"/></a> */}
                        {/* <Image height={'22px'} src="/assets/img/linkedin.png"/> */}
                    {/* </HStack> */}
                </Box>
            </HStack>

            <Box 
                borderLeft={'0.5rem solid lightgray'} 
                mt={'1rem'}
                pl="1rem"
                maxW={'40rem'}
                >
                <Text fontSize={'0.8rem'} fontWeight="400">
                <b>Omnika Dangol</b>, a trailblazing adventurer, etched her name in history as the first
                 flight attendant to conquer Mount Everest. Combining her love for aviation and mountaineering,
                  she shattered barriers and displayed extraordinary courage to reach the pinnacle of the world. 
                  Omnika's remarkable feat serves as a timeless inspiration for those who dare to dream and
                   persevere against all odds.
                </Text>
            </Box>

            <BoxText link="https://www.instagram.com/omnikadangol/" label="Follow me on Instagram" src="/assets/img/instagram.png"/>
            <BoxText link="https://www.facebook.com/saiomnika" label="Follow me on Facebook" src="/assets/img/facebook.png"/>
            <BoxText link="https://gofund.me/cf995f0e" label="Support my Seven Summit Project"/>
            <BoxText link="https://www.youtube.com/@omnikadangol7017" label="Subscribe to my Youtube channel!" src="/assets/img/youtube-logo.png"/>

            {/* <BoxArticle /> */}

            <Box
                mt={'1rem'}
            >
                <SectionTitle title="PHOTOS" />

                <HStack 
                    mt="1rem" 
                    gap={'0.5rem'}
                    overflowX="scroll"
                    scrollBehavior={'smooth'}
                    >
                    {
                        images?.map((item,index)=>(
                            <Photos setOpen={setOpen} item={item} index={index}/>
                            ))
                    }

                    <Lightbox
                        open={open}
                        close={() => setOpen(false)}
                        slides={images}
                    />
                </HStack>
            </Box>

            {/* <Box
                mt={'1rem'}
            >
                <SectionTitle title="SHOP" />

                <HStack 
                    mt="1rem" 
                    gap={'0.5rem'}
                    overflowX="scroll"
                    scrollBehavior={'smooth'}
                    >
                    <ShopCard />
                    <ShopCard />
                    <ShopCard />
                </HStack>
            </Box>

            <BoxText /> */}

        </Box>
    </Center>
    );
}


export default Mylink;